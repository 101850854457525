import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["video", "template"]
  static values = {
    loop: Boolean,
    index: Number,
    muted: Boolean,
  }
  static classes = ["muted"]

  queuingTime = 4

  initialize() {
    this.playlist = []
  }

  connect() {
    this.shufflePlaylist()
    this.queue(0)
    this.currentVideo.play()
  }

  queue(index) {
    if (this.queueIsFull) return
    const template = this.playlist[index]
    if (!template) return
    const clone = template.content.cloneNode(true)
    this.element.append(clone)
    if (this.nextVideo) this.nextVideo.load()
  }

  indexValueChanged() {
    if (this.isLastIndex) this.shufflePlaylist()
  }

  shufflePlaylist() {
    this.playlist = shuffleArray(this.templateTargets)
  }

  mute() {
    this.mutedValue = !this.mutedValue
  }

  mutedValueChanged() {
    console.log(this.currentVideo)
    if (this.currentVideo) this.currentVideo.muted = this.mutedValue
    this.element.classList.toggle(this.mutedClass, this.mutedValue)
  }

  play() {
    if (!this.currentVideo) return
    this.currentVideo.play()
  }

  next() {
    this.nextVideo.muted = this.mutedValue
    this.nextVideo.play()
    this.currentVideo.remove()
    this.indexValue = this.nextIndex
  }

  queueNext(event) {
    const video = event.target
    const timeLeft = video.duration - video.currentTime
    if (timeLeft > this.queuingTime) return
    this.queue(this.nextIndex)
  }

  set muted(value) {
    this.data.set("muted", value.toString())
  }

  get muted() {
    return this.data.get("muted") === "true"
  }

  get currentVideo() {
    if (!this.hasVideoTarget) return
    return this.videoTargets[0]
  }

  get nextVideo() {
    if (!this.hasVideoTarget) return
    return this.videoTargets[1]
  }

  get nextIndex() {
    if (this.isLastIndex) {
      return 0
    } else {
      return this.indexValue + 1
    }
  }

  get lastIndex() {
    return this.playlist.length - 1
  }

  get isLastIndex() {
    return this.indexValue == this.lastIndex
  }

  get queueIsFull() {
    return this.videoTargets.length == 2
  }
}

function shuffleArray(array) {
  let res = array
  for (var i = res.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = res[i]
    res[i] = res[j]
    res[j] = temp
  }
  return res
}
