import { Controller } from "@hotwired/stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static classes = ["active"]

  visit(event) {
    const isTarget = event.target == this.element
    this.element.classList.toggle(this.activeClass, isTarget)
    if (isTarget) navigator.history.push(event.detail.url)
  }
}
