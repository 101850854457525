// load@window->viewport-height#update resize@window->viewport-height#setViewportHeightCssVar

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  customVar = "--vh"

  connect() {
    this.doc = document.documentElement
    this.window = window
    this.update()
  }

  update() {
    requestAnimationFrame(() => {
      const innerHeight = this.innerHeight
      if (innerHeight === this.previousClientHeight) return
      this.doc.style.setProperty(this.customVar, `${innerHeight * 0.01}px`)
      this.previousClientHeight = this.innerHeight
    })
  }

  get innerHeight() {
    return this.window.innerHeight
  }
}
